import React, { useState, useEffect, useCallback } from 'react'
import { Widget, addResponseMessage, addUserMessage, renderCustomComponent, toggleMsgLoader, toggleWidget, isWidgetOpened, toggleInputDisabled } from 'react-chat-widget'
import 'react-chat-widget/lib/styles.css'
import DefaultMessage from './DefaultMessage'
import AnswerMessage from './AnswerMessage'
import WelcomeMessage from './WelcomeMessage'
import InvalidQuery from './InvalidQuery'
import { fetchProductsData, getChatVisibilityValue, updateChatVisibilityItem, getSummaryAndRecommendations } from './helper'

const MAX_MESSAGES_AMOUNT = 50
const DAY_IN_MS = 86400000
const CHAT_DISPLAY_BREAKPOINT = 768

const GCPContainer = ({ chatConfig, awsLambdaGcpChatUrl }) => {
  if (window.innerWidth <= CHAT_DISPLAY_BREAKPOINT) {
    return null
  }

  const [messagesHistory, setMessagesHistory] = useState(JSON.parse(sessionStorage.getItem('messagesHistory')) || [])
  const [isChatVisible, setIsChatVisible] = useState(getChatVisibilityValue() ?? true)

  useEffect(() => {
    renderWelcomeMessage()
    renderDefaultMessages()
    renderMessagesHistory()
    isChatVisible && toggleWidget()
  }, [])

  useEffect(() => {
    const visibilityTimoutInMs = chatConfig.visibility_timeout_in_ms || DAY_IN_MS

    updateChatVisibilityItem(isChatVisible, visibilityTimoutInMs)
  }, [isChatVisible])

  const renderWelcomeMessage = () => {
    renderCustomComponent(WelcomeMessage, { message: chatConfig.welcome_message })
  }

  const renderDefaultMessages = () => {
    const defaultMessages = [
      chatConfig.default_message1,
      chatConfig.default_message2,
      chatConfig.default_message3,
    ]

    defaultMessages.forEach(message => {
      renderCustomComponent(DefaultMessage, {
        message,
        handleMessageClick: handleDefaultMessageClick,
      })
    })
  }

  const MESSAGE_TYPES = {
    promptAnswer: (data) => renderCustomComponent(AnswerMessage, {
      products: data.products,
      answer: data.message,
      cannedStart: chatConfig.answer_reply_beginning,
      cannedEnd: chatConfig.answer_reply_end,
    }),
    userQuery: (data) => addUserMessage(data.message),
    promptCustomError: (data) => renderCustomComponent(InvalidQuery, { message: data.message }),
    promptDefaultError: (data) => addResponseMessage(data.message),
  }

  const renderMessagesHistory = () => {
    messagesHistory.forEach(data => {
      const action = MESSAGE_TYPES[data.type]

      if (action) {
        action(data)
      }
    })
  }

  const handleDefaultMessageClick = useCallback((message) => {
    const isChatInputDisabled = document.querySelector('.rcw-new-message.rcw-message-disable')

    if (!!isChatInputDisabled) return null

    addUserMessage(message)
    handleNewUserMessage(message)
  }, [])

  const renderAnswerMessage = useCallback((summary, products) => {
    renderCustomComponent(AnswerMessage, {
      products: products,
      answer: summary,
      cannedStart: chatConfig.answer_reply_beginning,
      cannedEnd: chatConfig.answer_reply_end,
    })
  }, [chatConfig])

  const updateMessagesHistory = useCallback((message, products, type) => {
    setMessagesHistory(prevState => {
      let newHistoryState = [...prevState, { message, products, type }]

      // Ensure the history does not exceed 50 messages
      if (newHistoryState.length > MAX_MESSAGES_AMOUNT) {
        newHistoryState = newHistoryState.slice(1)
      }

      sessionStorage.setItem('messagesHistory', JSON.stringify(newHistoryState))

      return newHistoryState
    })
  }, [])

  const handleNewUserMessage = useCallback(async (newMessage) => {
    toggleInputDisabled()
    updateMessagesHistory(newMessage, null, 'userQuery')
    toggleMsgLoader()

    const { summary, productIds } = await getSummaryAndRecommendations(newMessage, awsLambdaGcpChatUrl)
    const products = productIds ? await fetchProductsData(productIds) : null

    if (summary && products) {
      renderAnswerMessage(summary, products)
      updateMessagesHistory(summary, products, 'promptAnswer')
    } else {
      const errorMessage = chatConfig.invalid_query

      renderCustomComponent(InvalidQuery, { message: errorMessage })
      updateMessagesHistory(errorMessage, null, 'promptCustomError')
    }

    toggleMsgLoader()
    toggleInputDisabled()
  }, [updateMessagesHistory, renderAnswerMessage])

  return (
    <div className="GCPContainer" onClick={() => setIsChatVisible(isWidgetOpened())}>
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title={(<div>Plugin Boutique Shopping Assistant <div className='rcw-header info'>(beta)</div></div>)}
        subtitle=""
        showTimeStamp={false}
        emojis={false}
        showBadge={false}
        senderPlaceHolder={'Start typing something...'}
      />
    </div>
  )
}

export default GCPContainer
