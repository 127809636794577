import React from "react";
import PropTypes from 'prop-types'
import Loadable from 'react-loadable';
import KlarnaWidget from "./KlarnaWidget";
import csrfToken from '../../src/csrf_token'
import '../../src/mess'

const LoadableDropIn = Loadable({
  loader: () => import('braintree-web-drop-in-react'),
  loading() {
    return <div>Loading...</div>
  }
});

const LoadableReCAPTCHA = Loadable({
  loader: () => import('react-google-recaptcha'),
  loading() {
    return <div>Loading...</div>
  }
});
class PaymentDropIn extends React.Component {
  instance

  state = {
    nonce: '',
    deviceData: '',
    bt_payment_type: '',
    card_type: '',
    user_password: '',
    user_password_confirmation: '',
    terms_checkbox_value: 'no',
    show_yen_note: this.props.show_yen_note,
    news_checkbox_value: this.props.newsletter_subscription,
    marketing_checkbox_value: this.props.other_newsletter_subscription,
    error_message: this.props.errors,
    is_payment_needed: this.props.isPaymentNeeded,
    klarna: this.props.klarna,
    klarna_payment_methods: this.props.klarna.paymentMethodCategories || [],
    klarna_payment_method: ((this.props.klarna.paymentMethodCategories || [])[0] || {}).identifier,
    braintree: this.props.braintree,
    selected_payment_method: '',
    show_paypal_conversion_note: false,
    forceDisableBuy: false,
    braintree_total_cash_payment: this.props.braintree_total_cash_payment,
    timestamp: Date.now(),
    bt_payment_view: 'options',
    enabledCaptchaType: this.props.enabledCaptchaType,
    turnstileReady: !!window.turnstile,
    turnstileMounted: false
  };

  constructor(props) {
    super(props);
    this.buy = this.buy.bind(this);
    this.refreshCart = this.refreshCart.bind(this);
    this.disablePayment = this.disablePayment.bind(this);
    this.selectPayment = this.selectPayment.bind(this);
    this.initializeDropIn = this.initializeDropIn.bind(this);
    this.disableNavigation = this.disableNavigation.bind(this);

    this.paymentSubmitBtn = React.createRef()

    window.addEventListener('cloudflare-turnstile-ready', () => this.setState({ turnstileReady: true }))
  }

  componentDidMount() {
    this.mountTurnstile()

    Mess.subscribe('password_change', (value) => {
      this.setState({ user_password: value })
    });

    Mess.subscribe('password_confirmation_change', (value) => {
      this.setState({ user_password_confirmation: value })
    });

    Mess.subscribe('terms_checkbox_change', (value) => {
      this.setState({ terms_checkbox_value: value })
    });

    Mess.subscribe('news_checkbox_change', (value) => {
      this.setState({ news_checkbox_value: value })
    });

    Mess.subscribe('marketing_checkbox_change', (value) => {
      this.setState({ marketing_checkbox_value: value })
    });

    Mess.subscribe('klarna_payment_method_change', (value) => {
      this.setState({ klarna_payment_method: value })
    });

    Mess.subscribe('refresh_cart', this.refreshCart)

    Mess.subscribe('disablePayment', this.disablePayment)

    this.displayErrors()

    if(this.state.klarna_payment_methods.length === 0) {
      this.selectPayment('bt');
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.error_message !== prevState.error_message) {
      this.displayErrors()
    }

    this.mountTurnstile()
  }

  mountTurnstile() {
    if (!this.state.turnstileReady || this.state.turnstileMounted) return

    const container = document.querySelector('#turnstile-captcha')
    if (container.children.length > 0) [...container.children].forEach(child => child.remove())

    window.turnstile.render('#turnstile-captcha', {
      sitekey: process.env.TURNSTILE_SITE_KEY,
      callback: (token) => this.setState({ captchaToken: token })
    })

    this.setState({ turnstileMounted: true })
  }

  displayErrors = () => {
    const { error_message } = this.state

    if (error_message) {
      Mess.publish('show_error', error_message)
    }
  }

  initializeDropIn(instance) {
    this.instance = instance

    const initial_view = instance.isPaymentMethodRequestable() ? 'credit_card' : 'options'

    this.setState({
      bt_payment_view: initial_view
    })

    instance.on('paymentOptionSelected', (event) => {
      this.setState({
        show_paypal_conversion_note: (event.paymentOption === 'paypal')
      })
    })

    instance.on('changeActiveView', (view) => {
      this.setState({
        bt_payment_view: view.newViewId
      })

      if(view.newViewId === 'options' && view.previousViewId === 'paypal') {
        this.setState({show_paypal_conversion_note: false})
      }
    })
  }

  disablePayment() {
    this.setState({forceDisableBuy: true});
  }

  convertSymbolToIso(symbol) {
    return { '£' : 'GBP', '$' : 'USD', '€' : 'EUR','¥' : 'JPY' }[symbol]
  }

  refreshCart() {
    $.ajax({
      url: '/checkout/payment_dropin',
      dataType: 'json',
      method: 'GET',
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      success: function(data) {
        let newState = {
          is_payment_needed: data.isPaymentNeeded,
          braintree: data.braintree,
          braintree_total_cash_payment: data.braintree_total_cash_payment,
          klarna: data.klarna,
          klarna_payment_methods: data.klarna.paymentMethodCategories || [],
          klarna_payment_method: ((data.klarna.paymentMethodCategories || [])[0] || {}).identifier,
          timestamp: Date.now(),
          forceDisableBuy: false,
          show_yen_note: data.show_yen_note
        }

        if(newState.klarna_payment_methods.length === 0) {
          newState.selected_payment_method = 'bt'
        }

        this.setState(newState)
      }.bind(this)
    });
  }

  selectPayment(method) {
    const update = { selected_payment_method: method }
    if (this.state.selected_payment_method !== 'bt' && method === 'bt') {
      update.turnstileMounted = false
    }
    this.setState(update)
  }

  disableNavigation() {
    if (this.paymentSubmitBtn.current) {
      this.paymentSubmitBtn.current.style.display = 'none'
    }
    Mess.publish('disable_navigation', true);
  }

  enableNavigation() {
    if (this.paymentSubmitBtn.current) {
      this.paymentSubmitBtn.current.style.display = 'inline-block'
    }
    Mess.publish('disable_navigation', false);
  }

  async buy(ev) {
    if(this.state.selected_payment_method === 'klarna'){
      const thus = this;

      ev.preventDefault();

      this.disableNavigation()

      Klarna.Payments.authorize({
          payment_method_category: thus.state.klarna_payment_method
        },
        {},
        (res) => {
          if(res.approved) {
            document.getElementsByName('authorization_token')[0].value = res.authorization_token;
            ev.target.submit();
          } else {
            this.enableNavigation()
          }
        }
      );
    } else {
      this.disableNavigation()

      ev.preventDefault();

      try {
        this.setState({ error_message: '' })
        if (this.state.is_payment_needed) {
          const threeDSecureParameters = {
            amount: this.state.braintree.threeDSecure.amount,
            email: this.state.braintree.email,
            challengeRequested: true
          };
          const { nonce, type, details } = await this.instance.requestPaymentMethod({ threeDSecure: threeDSecureParameters });
          const deviceData = this.instance._dataCollector.getDeviceData();
          this.setState({ nonce: nonce, deviceData: deviceData, bt_payment_type: type, card_type: details.cardType });
        }

        let paymentResponse = await window.fetch(ev.target.action + '.json', { redirect: 'manual', method: 'post', body: new FormData(ev.target) })

        if (!paymentResponse.ok && paymentResponse.type !== 'opaqueredirect') {
          let parsedResponse = await paymentResponse.json()
          throw new Error(parsedResponse);
        }

        location.reload();
        window.scrollTo(0, 0)
      } catch(error) {
        this.enableNavigation()

        this.setState({
          error_message: error.message
        })
      }
    }
  }

  render() {
    const state = this.state

    const isPaymentBtnActive = (state.bt_payment_view !== 'options' || !state.is_payment_needed) && !state.forceDisableBuy || state.selected_payment_method === 'klarna'

    return (
      <form className="bt-dropin" action="/checkout/transition" method='post' onSubmit={ this.buy }>
        <input type="hidden" name="transition" value="checkout"/>
        <input type="hidden" name="authenticity_token" value={ csrfToken() } />

        <input type="hidden" name="user[email]" value={ this.props.braintree.email } />
        <input type="hidden" name="user[password]" value={ state.user_password } />
        <input type="hidden" name="user[password_confirmation]" value={ state.user_password_confirmation } />

        { !this.props.isCurrentUserPresent && (
          <div>
            <input type="hidden" name="user[terms]" value={ state.terms_checkbox_value } />
            <input type="hidden" name="user[newsletter_subscription]" value={ state.news_checkbox_value } />
            <input type="hidden" name="user[other_newsletter_subscription]" value={ state.marketing_checkbox_value } />
          </div>
        )}
        { state.is_payment_needed && (
          <div key={ state.timestamp }>
            <h3 className="select-payment-method">Select Payment Method</h3>
            <div>
              { state.klarna_payment_methods.length > 0 &&
                (<a className='button-plain pay-with-klarna' onClick={ () => this.selectPayment('klarna') }>Pay <b>{ state.klarna.total_cash_payment }</b> with <span>Klarna</span></a>)
              }
            </div>
            <div>
              <a className='button-plain pay-with-bt' onClick={ () => this.selectPayment('bt') }>Pay <b>{ this.state.braintree_total_cash_payment }</b> with <span>PayPal</span> or <span>Card</span></a>
            </div>
            <input type="hidden" name="order[payment][nonce]" value={ state.nonce }/>
            <input type="hidden" name="order[payment][device_data]" value={ state.deviceData } />
            <input type="hidden" name="order[payment][bt_payment_type]" value={ state.bt_payment_type } />
            <input type="hidden" name="order[payment][card_type]" value={ state.card_type } />
            <input type="hidden" name="order[payment][method]" value={ state.selected_payment_method } />

            { state.show_yen_note && (<div className='payment-note'>Please note: you will be charged in { this.convertSymbolToIso(this.state.braintree_total_cash_payment.charAt(0)) } as payments cannot be accepted in { this.convertSymbolToIso('¥') }.</div>) }

            { state.selected_payment_method === 'bt' &&
              (<div>
                <LoadableDropIn
                options={ state.braintree }
                onInstance={ this.initializeDropIn }/>
                { state.show_paypal_conversion_note && (<div className="paypal-note">Please note: the amount you pay may slightly vary due to PayPal&apos;s own currency exchange rates/fees.</div>) }
              </div>)
            }

            { state.selected_payment_method === 'klarna' &&
              (<KlarnaWidget {...state.klarna}/>)
            }
          </div>
        )}

        {
          state.selected_payment_method !== 'klarna' && state.enabledCaptchaType && (
            <div className="third-step-captcha">
              {
                state.enabledCaptchaType === 'turnstile' ? (
                    <div id='turnstile-captcha' />
                ) : state.enabledCaptchaType === 'google' ? (
                    <LoadableReCAPTCHA
                      sitekey={process.env.RECAPTCHA_SITE_KEY}
                    />
                ) : null
              }
            </div>
          )
        }

        {
          isPaymentBtnActive && (
            <button id="submit-button" ref={this.paymentSubmitBtn} disabled={ !isPaymentBtnActive } className="btn-pay btn-pay-inner button-plain" type="submit">Buy</button>
          )
        }
      </form>
    );
  }
}

PaymentDropIn.propTypes = {
  isCurrentUserPresent: PropTypes.bool,
  show_yen_note: PropTypes.bool,
  newsletter_subscription: PropTypes.bool,
  other_newsletter_subscription: PropTypes.bool,
  errors: PropTypes.string,
  isPaymentNeeded: PropTypes.bool,
  klarna: PropTypes.object,
  braintree: PropTypes.object,
  braintree_total_cash_payment: PropTypes.string
}

export default PaymentDropIn;
